import './App.css';
import TestUI from "./components/TestUI";
import { CrumbsTest, testConfig, TestStep } from "./components/CrumbsTest";

function App() {

    const thirdPartyCookieSandboxTest = new CrumbsTest("Third party cookie sandbox", "THC_SANDBOX", [
        new TestStep("Initialize", async (test) => {
            await test.navigateTo(testConfig.MAIN_DOMAIN);
        }),
        new TestStep("Create third party cookie", async (test) => {
            let time = new Date().getTime();
            await test.createCookie("cookie", time);
            await test.saveVariable("cookie", time);
        }),
        new TestStep("Change domain", async (test) => {
            await test.navigateTo(testConfig.SECOND_DOMAIN);
        }),
        new TestStep("Check cookie visibility", async (test) => {
            const value = await test.readCookie("cookie");
            if (value === test.readVariable("cookie")) {
                throw new Error("third party cookie shared between domains")
            }
        }),
        new TestStep("Reset", async (test) => {
            await test.navigateTo(testConfig.MAIN_DOMAIN);
        })
    ])

    const thirdPartyCookieBlockTest = new CrumbsTest("Third party cookie blocked", "THC_BLOCKED", [
        new TestStep("Create third party cookie", async (test) => {
            let time = new Date().getTime();
            await test.createCookie("cookie", time, false);
            await test.saveVariable("cookie", time);
        }),
        new TestStep("Check cookie value", async (test) => {
            const initValue = await test.readVariable("cookie");
            const cookieValue = await test.readCookie("cookie")
            if (cookieValue.toString() === initValue) {
                throw new Error("Third party cookie are not blocked")
            }
        }),
    ])

    const referrerPathCrop = new CrumbsTest("Referrer path crop", "referrerPathCrop", [
        new TestStep("Change path", async (test) => {
            await test.navigateTo(testConfig.MAIN_DOMAIN, "/referer");
        }),
        new TestStep("Check Referrer path", async (test) => {
            let result = await test.readHeader("referer")
            if (new URL(result).pathname === document.location.pathname) {
                throw new Error("Referer path is not removed")
            }
        }),
        new TestStep("Reset", async (test) => {
            await test.navigateTo(testConfig.MAIN_DOMAIN, "/");
        }),
    ])

    const DNT = new CrumbsTest("Do Not Track signal", "DNT", [
        new TestStep("Check DNT header", async (test) => {
            let result = await test.readHeader("DNT")
            if (parseInt(result) !== 1) {
                throw new Error("DNT signal is off")
            }
        }),
    ])

    const SecGPC = new CrumbsTest("SecGPC signal", "SecGPC", [
        new TestStep("Check SecGPC header", async (test) => {
            let result = await test.readHeader("sec-gpc")
            if (parseInt(result) !== 1) {
                throw new Error("DNT signal is off")
            }
        }),
        new TestStep("Check globalPrivacyControl variable", async (test) => {
            if (navigator.globalPrivacyControl !== true) {
                throw new Error("globalPrivacyControl not found")
            }
        }),
    ])

    const XClientData = new CrumbsTest("Client data", "X-Client-Data", [
        new TestStep("Check X-Client-Data header", async (test) => {
            let value = "X-Client-Data"
            let result = await test.readHeader("X-Client-Data", value)
            if (result === value) {
                throw new Error("X-Client-Data not removed")
            }
        }),
    ])

    const marketingParameters = new CrumbsTest("Remove utm parameters", "marketingParameters", [
        new TestStep("Add one utm parameter", async (test) => {
            const extraQueryParams = new Map([
                ["utm_source", "website"],
                ["param", "value"]
            ]);
            await test.navigateTo(testConfig.MAIN_DOMAIN, "/marketing", extraQueryParams)
        }),
        new TestStep("Check parameter", async (test) => {
            const url = new URL(document.location.href);
            if (url.searchParams.get('utm_source') !== null) {
                throw new Error("Utm parameters not removed")
            }
            if (url.searchParams.get('param') !== 'value') {
                throw new Error("Non utm parameter removed")
            }
        }),
        new TestStep("Add multiple utm parameters", async (test) => {
            const extraQueryParams = new Map([
                ["utm_source", "website"],
                ["utm_campaign", "google"],
                ["param", "value"]
            ]);
            await test.navigateTo(testConfig.MAIN_DOMAIN, "/marketing", extraQueryParams)
        }),
        new TestStep("Check parameters", async (test) => {
            const url = new URL(document.location.href);
            if (url.searchParams.get('utm_source') !== null || url.searchParams.get('utm_campaign') !== null) {
                throw new Error("Utm parameters not removed")
            }
            if (url.searchParams.get('param') !== 'value') {
                throw new Error("Non utm parameter removed")
            }
        }),
        new TestStep("Reset", async (test) => {
            await test.navigateTo(testConfig.MAIN_DOMAIN, "/");
        }),
    ])

    const cnameCloakingTracker = new CrumbsTest("CNAME cloaking tracker", "cnameCloakingTracker", [
        new TestStep("Change domain", async (test) => {
            await test.navigateTo(testConfig.CNAME_DOMAIN);
        }),
        new TestStep("Request tracker", async (test) => {
            const code = await test.request(testConfig.TRACKER_URL);
            if (code === 200) {
                throw  new Error("Fake tracker is not blocked")
            }
        }),
        new TestStep("Request tracker cname", async (test) => {
            const code = await test.request(testConfig.TRACKER_CNAME_URL);
            if (code === 200) {
                throw  new Error("Cname not detected - fake tracker not blocked")
            }
        }),
        new TestStep("Reset", async (test) => {
            await test.navigateTo(testConfig.MAIN_DOMAIN);
        }),
    ])

    const documentCookieSandboxTest = new CrumbsTest("Third party cookie sandbox with javascript", "THC_SANDBOX_JS", [
        new TestStep("Initialize", async (test) => {
            await test.navigateTo(testConfig.MAIN_DOMAIN);
        }),
        new TestStep("Add 3rd party iframe", async (test) => {
            await test.openIFrame(`${window.location.protocol}//${testConfig.SECOND_DOMAIN}/cookie-iframe.html`);
            await test.wait(2000);
        }),
        new TestStep("Change domain", async (test) => {
            await test.navigateTo(testConfig.SECOND_DOMAIN);
        }),
        new TestStep("Check cookie visibility", async (test) => {
            const isCookieSandboxed = document.cookie.split(";").map(value => value.trim()).filter(value => value.indexOf("cookie=sandbox") === 0).length === 0;

            // clean up cookies, so we can start a new test with no cookies
            const cookies = document.cookie.split(";");
            for (const cookie of cookies) {
                if (cookie.trim() !== "") {
                    const eqPos = cookie.indexOf("=");
                    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
            }

            if (!isCookieSandboxed) {
                throw new Error("Sanboxing didn't not work!")
            }
        }),
        new TestStep("Reset", async (test) => {
            await test.navigateTo(testConfig.MAIN_DOMAIN);
        })
    ])

    const localStorageSandboxTest = new CrumbsTest("Local storage sandbox with javascript", "STORAGE_SANDBOX_JS", [
        new TestStep("Initialize", async (test) => {
            await test.navigateTo(testConfig.MAIN_DOMAIN);
        }),
        new TestStep("Add 3rd party iframe", async (test) => {
            await test.openIFrame(`${window.location.protocol}//${testConfig.SECOND_DOMAIN}/storage-iframe.html`);
            await test.wait(2000);
        }),
        new TestStep("Change domain", async (test) => {
            await test.navigateTo(testConfig.SECOND_DOMAIN);
        }),
        new TestStep("Check local storage visibility", async (test) => {
            let isKeySandboxed = localStorage.getItem("storage") === null;
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (key.match(/\S+storage/) !== null) {
                    isKeySandboxed = isKeySandboxed && true;
                }
            }

            // clean up localStorage, so we can start a new test with empty localStorage
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (key.endsWith("storage")) {
                    localStorage.removeItem(key);
                }
            }

            if (!isKeySandboxed) {
                throw new Error("Sanboxing didn't not work!")
            }
        }),
        new TestStep("Reset", async (test) => {
            await test.navigateTo(testConfig.MAIN_DOMAIN);
        })
    ])

    const hyperlinkAuditingTest = new CrumbsTest("Hyperlink auditing", "HYPERLINK", [
        new TestStep("Initialize", async (test) => {
            await test.navigateTo(testConfig.MAIN_DOMAIN);
        }),
        new TestStep("Create link", async (test) => {
            let value = Math.random().toString();
            let sessionId = Math.random().toString();
            await test.saveVariable("session", sessionId);
            await test.saveVariable("value", value);

            const link = document.createElement('a');
            link.href = window.location;
            link.ping = `https://${testConfig.MAIN_DOMAIN}/api/storage/save/${sessionId}/${value}`;
            link.textContent = "Hyperlink";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }),
        new TestStep("Check ping api", async (test) => {
            // we wait to make sure the ping value is stored
            await test.wait(3000);
            let sessionId = test.readVariable("session");
            let value = test.readVariable("value");
            const response = await fetch(`https://${testConfig.MAIN_DOMAIN}/api/storage/read/${sessionId}`, {
                credentials: 'include',
                cache: 'no-cache',
            })
            if (response.status !== 200) {
                throw new Error("can't read value")
            }
            let body = await response.text();
            if (body === value) {
                throw new Error("hyperlink has been called")
            }
        }),
        new TestStep("Reset", async (test) => {
            await test.navigateTo(testConfig.MAIN_DOMAIN);
        }),
    ])

    return (
        <div className="App">
            <TestUI test={thirdPartyCookieSandboxTest}/>
            <TestUI test={documentCookieSandboxTest}/>
            <TestUI test={localStorageSandboxTest}/>
            <TestUI test={thirdPartyCookieBlockTest}/>
            <TestUI test={referrerPathCrop}/>
            <TestUI test={DNT}/>
            <TestUI test={SecGPC}/>
            <TestUI test={XClientData}/>
            <TestUI test={marketingParameters}/>
            <TestUI test={cnameCloakingTracker}/>
            <TestUI test={hyperlinkAuditingTest}/>
        </div>
    );
}

export default App;
