export var testConfig = {
    MAIN_DOMAIN: "crumbs-benchmark.web.app",
    SECOND_DOMAIN: "crumbs-benchmark.firebaseapp.com",
    CNAME_DOMAIN: "crumbs-benchmark.sertook.com",
    TRACKER_URL: "https://tracking.crumbs-benchmark.sertook.com/event/script.js",
    TRACKER_CNAME_URL: "https://cname.crumbs-benchmark.sertook.com/event/script.js",
    API_DOMAIN: "europe-west1-crumbs-benchmark.cloudfunctions.net"
}

export function TestStep(name, action) {
    this.name = name;
    this.action = action;
}

export function CrumbsTest(title, id, steps) {
    this.title = title;
    this.id = id;
    this.steps = steps;

    this.getResult = function () {
        return {
            errorStep: localStorage.getItem(this.id + "_errorStep"),
            errorMessage: localStorage.getItem(this.id + "_error"),
            success: localStorage.getItem(this.id + "_result")
        }
    }

    this.currentStep = function () {
        return parseInt(this.readVariable("step")) || 0
    }

    this.error = function () {
        this.readVariable("error")
    }

    this.inProgress = function () {
        return this.currentStep() !== 0;
    }

    this.executeNextStep = async function () {
        let currentStep = this.currentStep();
        if (currentStep === 0) {
            window.location.href = "#" + this.id;                 //Go to the target element.
            currentStep = 1;
            this.saveVariable("step", currentStep)
            this.saveVariable("error", null)
            this.clearResults()
        }
        if (currentStep > 0 && currentStep <= this.steps.length) {
            try {
                await this.wait(100)
                // we set next step before running the actual test, in case we have to navigate to a different url
                // the app will know what test it should run.
                this.saveVariable("step", currentStep + 1)
                await this.steps[currentStep - 1].action(this)
                return false
            } catch (e) {
                console.warn("catch error during step", e)
                this.saveVariable("error", e.toString())
                this.saveVariable("errorStep", currentStep)
                this.saveVariable("step", this.steps.length + 1)
            }
        }
        await this.navigateTo(testConfig.MAIN_DOMAIN)
        this.saveVariable("step", null)
        let error = this.readVariable("error");
        let errorStep = this.readVariable("errorStep");
        if (error) {
            localStorage.setItem(this.id + "_errorStep", errorStep)
            localStorage.setItem(this.id + "_error", error)
            localStorage.setItem(this.id + "_result", "false")
        } else {
            localStorage.setItem(this.id + "_result", "true")
        }
        this.clearVariables();


        return true
    }

    this.clearResults = function () {
        localStorage.removeItem(this.id + "_errorStep")
        localStorage.removeItem(this.id + "_error")
        localStorage.removeItem(this.id + "_result")
    }

    this.clearVariables = function () {
        const url = window.location.protocol + "//" + window.location.host + window.location.pathname;
        window.history.pushState({path: url}, '', url);
    }

    this.readVariable = function (name) {
        const query = new URLSearchParams(window.location.search);
        return query.get(id + "_" + name)
    }
    this.saveVariable = function (name, value) {
        const query = new URLSearchParams(window.location.search);
        query.delete(id + "_" + name)
        if (value != null) {
            query.append(id + "_" + name, value)
        }
        const path = window.location.pathname + '?' + query.toString() + document.location.hash;
        window.history.replaceState(null, '', path);
    }

    this.navigateTo = async function (domain, path = window.location.pathname, extraQueryParams = new Map()) {
        const existingQueryParams = new URLSearchParams(window.location.search);
        extraQueryParams.forEach((v, k) => {
            if (!existingQueryParams.has(k)) {
                existingQueryParams.append(k, v);
            }
        })
        // check dev env
        if (window.location.host === "localhost:3000") {
            domain = window.location.host;
        }
        // IMPORTANT! This should cause a reload of the page, otherwise Crumbs will not detect the navigation to a new page
        window.location.replace(window.location.protocol + "//" + domain + path + '?' + existingQueryParams.toString() + document.location.hash);
        return await this.wait(500)
    }

    this.request = async function (url) {
        try {
            const response = await fetch(url, {
                cache: 'no-cache'
            })
            return response.status
        }catch (e) {
            return 0
        }
    }

    this.createCookie = async function (name, value, checkValue = true) {
        const response = await fetch(`https://${testConfig.API_DOMAIN}/api/cookie/create/${name}/${value}`, {
            credentials: 'include',
            cache: 'no-cache',
        })
        if (response.status !== 200) {
            throw new Error("can't create cookie")
        }

        if (checkValue) {
            const cookieValue = await this.readCookie(name)
            if (cookieValue !== value.toString()) {
                throw new Error("Third party cookies are blocked")
            }
        }
    }

    this.readCookie = async function (name) {
        const responseRead = await fetch(`https://${testConfig.API_DOMAIN}/api/cookie/get/${name}`, {
            cache: 'no-cache',
            credentials: 'include'
        })
        if (responseRead.status !== 200) {
            throw new Error("can't read cookie")
        }
        return await responseRead.text()
    }

    this.readHeader = async function (name, value) {
        const myHeaders = new Headers();
        if (value) {
            myHeaders.append(name, value)
        }
        const responseRead = await fetch(`https://${testConfig.API_DOMAIN}/api/header/get/${name}`, {
            referrerPolicy: 'no-referrer-when-downgrade',
            cache: 'no-cache',
            headers: myHeaders,
        })
        if (responseRead.status !== 200) {
            throw new Error("can't read header")
        }
        return await responseRead.text()
    }

    this.openIFrame = async function (url) {
        const iframe = document.createElement('iframe');
        iframe.src = url;
        iframe.width = "0";
        iframe.height = "0";
        document.body.appendChild(iframe);
        await this.wait(1000);
    }

    this.wait = function (time) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, time);
        });
    }

    this.reset = function () {
        this.clearVariables()
        this.clearResults()
        window.location.href = "/#" + this.id
    }
}

