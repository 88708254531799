import Button from "@material-ui/core/Button";
import {
    LinearProgress, Link,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@material-ui/core";
import {Component} from "react";
import "./TestUI.css"


class TestUI extends Component {


    constructor(props) {
        super(props);
        this.mobile = this.isMobile()
        this.test = props.test;
        if (this.props.test.inProgress() && this.isAuto()) {
            console.log("resume " + this.props.test.id);
            this.continueTest();
        }
    }

    reset = async () => {
        this.test.reset()
        this.forceUpdate()
    }

    startAuto = async () => {
        this.test.saveVariable("auto", true)
        await this.continueTest()
    }

    continueTest = async () => {
        const finish = await this.test.executeNextStep()
        this.forceUpdate()
        if (!finish && this.isAuto()) {
            await this.continueTest();
        }
    }

    isAuto() {
        return this.test.readVariable("auto") ?? false
    }

    isMobile() {
        return window.innerWidth < 600;
    }

    resize() {
        if (this.mobile !== this.isMobile()) {
            this.mobile = !this.mobile;
            this.forceUpdate();
        }
    }

    componentDidMount() {
        window.addEventListener('resize', () => this.resize())
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.resize())
    }

    render() {

        const inProgress = this.props.test.inProgress();

        let activeStep = this.test.currentStep() - 1;
        let error;
        let errorStep;

        var resultClass = ""

        if (!inProgress) {
            resultClass = "TestFinished"
            const result = this.test.getResult();
            if (result.success === "true") {
                resultClass += " TestSuccess"
                activeStep = this.test.steps.length
            } else if (result.errorMessage) {
                resultClass += " TestError"
                activeStep = parseInt(result.errorStep) - 1;
                errorStep = activeStep
                error = result.errorMessage;
            }
        }
        return (
            <div className={`TestUIRoot ${this.test.id} ${resultClass}`}>
                <Paper elevation={2}>
                    <h2 id={this.test.id}>
                        {this.test.title}
                    </h2>
                    <Stepper orientation={this.mobile ? "vertical" : "horizontal"} activeStep={activeStep}
                             alternativeLabel={!this.mobile}>
                        {
                            this.test.steps.map((step, index) => {
                                const labelProps = {};
                                if (errorStep === index) {
                                    labelProps.optional = (
                                        <Typography variant="caption" color="error">
                                            {error}
                                        </Typography>
                                    );
                                    labelProps.error = true;
                                }
                                return (
                                    <Step key={index}>
                                        <StepLabel
                                            style={{"textAlign": this.mobile ? "start" : "center"}} {...labelProps}>{step.name}</StepLabel>
                                    </Step>)
                            })
                        }
                    </Stepper>

                    <div className="BottomBar">
                        <Button className="ContinueButton" variant="contained" color="primary"
                                onClick={this.continueTest}>Continue</Button>
                        <Button className="AutoButton" variant="contained" color="primary"
                                onClick={this.startAuto}>Auto</Button>
                        <Button className="ResetButton" variant="contained" color="primary"
                                onClick={this.reset}>Reset</Button>
                    </div>
                    {inProgress ? <LinearProgress/> : null}
                </Paper>
            </div>
        );
    }
}

export default TestUI;
